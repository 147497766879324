import { ContainedBtn } from '@components/Buttons';
import { SeoHeader } from '@components/Common';
import { CookiePolicy } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import { Routes } from '@navigation';
import { mc } from '@styles';
import React, { FC } from 'react';

const NotFoundPage: FC = () => {
  return (
    <>
      <NavigationHeader />
      <CookiePolicy />
      <div className={mc('w-full min-h-[80vh] px-4 py-8', 'bg-seafoam', 'flex justify-center items-center')}>
        <div className="text-center flex flex-col items-center">
          <h1 className="m-0 p-0 text-5xl font-literata leading-tight text-center font-medium text-ocean-blue uppercase">404</h1>
          <h2 className="m-0 p-0 text-3xl font-literata leading-tight text-center font-medium text-ocean-blue">Page not found</h2>
          <p className="m-0 p-0 mt-2 text-2xl font-raleway leading-normal text-center font-semibold text-neutral-grey-700">
            We can't seem to find what you're looking for.
          </p>
          <ContainedBtn className="mt-8" color="orange" href={Routes.home.url}>
            {'Return To Home'}
          </ContainedBtn>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage;

export const Head = () => <SeoHeader title="Not Found" />;
